<template>
  <div class="bg-light b-30 p-4 mb-4">
    <sk-list v-if="loading" :height="0.03" :items-count="6"></sk-list>
   
    <div v-if="!loading && applications.length === 0" class="text-center">
      {{ $t('applications.applications-not-found') }}
    </div>


    <!-- <div class="row table-blue-wide">
      <div class="col">N</div>
      <div class="col">{{$t("applications.subject")}}</div>
      <div class="col">{{$t("applications.date-written")}}</div>
      <div class="col">{{$t("applications.resolution")}}</div>
      <div class="col">{{$t("applications.status")}}</div>
      <div class="col"></div>
      <div class="col">{{ $t('applications.pdf') }}</div>

    </div>


    <div class="row table-blue-wide" v-if="!loading && applications.length > 0" v-for="application of applications">
      <div  class="col" @click="showApplication(application)" data-label="N">{{ application.correspondenceID }}</div>
        <div  class="col" @click="showApplication(application)" :data-label="$t('applications.subject')">{{ helpers.getLocalizedField(application, 'title') }}</div>
        <div  class="col" :data-label="$t('applications.date-written')">{{ application.correspondence_date }}</div>
        <div  class="col" :data-label="$t('applications.resolution')"> <span>{{ application.comment }}</span>
          <div  v-for="gadavadeba of application.gadavadebebi" v-html="gadavadeba"></div> 

        </div>
        <div  :data-label="$t('applications.status')" class="text-center col">
          <img :src="helpers.getApplicationIconPath(application)" :title="helpers.getLocalizedField(application, 'status')" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
        </div>
        <div  class="col" @click="getApplicationProgress(application.correspondenceID)">123</div>
        <div  class="col"><span  v-if="application.onlineVersion" @click="downloadPDF(application.correspondenceID)">{{ application.onlineVersion }}</span></div>
        
        


    </div> -->









    <table v-if="!loading && applications.length > 0" class="table-blue-wide w-100">
      <thead>
      <tr>
        <th>N</th>
        <th>{{$t("applications.subject")}}</th>
        <th>{{$t("applications.date-written")}}</th>
        <th>{{$t("applications.resolution")}}</th>
        <th class="text-center">{{$t("applications.status")}}</th>
        <th></th>
        <th class="text-center">{{ $t('applications.pdf') }}</th>
     
      </tr>
      </thead>
      <tbody>
        
        
      <tr class="cursor-pointer"  v-for="application of applications">

         

        <td @click="showApplication(application)" data-label="N">{{ application.correspondenceID }}</td>
        <td @click="showApplication(application)" :data-label="$t('applications.subject')">{{ helpers.getLocalizedField(application, 'title') }}</td>
        <td :data-label="$t('applications.date-written')">{{ application.correspondence_date }}</td>
        <td :data-label="$t('applications.resolution')"> <span>{{ application.comment }}</span>
          <div v-for="gadavadeba of application.gadavadebebi" v-html="gadavadeba"></div>


      



        </td>
        <td :data-label="$t('applications.status')" class="text-center">
          <img :src="helpers.getApplicationIconPath(application)" :title="helpers.getLocalizedField(application, 'status')" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
        </td>
        <td @click="getApplicationProgress(application.correspondenceID)"><img src="/static/images/icons/arrow-red.png"></td>
        <td><span  v-if="application.onlineVersion" @click="downloadPDF(application.correspondenceID)">{{ application.onlineVersion }}</span></td>
        
        


        <td     :style="progress.filter(o=>o.correspondenceID==application.correspondenceID).length>0?'display:table-row':'display:none;'" :id="'progress_'+application.correspondenceID"> 


 
                  <div style="width:8.3%;display: table-cell;"></div>
                    <div style="width:8.3%;display: table-cell;"></div>
                    <div style="width:8.3%;display: table-cell;"></div>
               
                  <div   class="progressBox"  >


                 
                  
                  <div v-for="(prog,i) of progress.filter(o=>o.correspondenceID==application.correspondenceID)" :key="i" :style="progress[i+1]?'color:grey':'color:black'">

                    
                    
                  <span>{{ prog.statusDate }} {{ prog.statusTime }} - </span> <span v-html="prog.comment"></span>   -     {{helpers.getLocalizedField(prog, 'fname')+' '+helpers.getLocalizedField(prog, 'lname')}}
                  
                  
                    <img :src="helpers.getApplicationProgressIconPath(prog)"   class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">

                    <div class="d-flex justify-content-center" v-if="progress[i+1]">
                    <div style="width: 100px;height: 100px;">

                      <img src="/static/images/icons/arrowProgress.png"   class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                    </div>
                  </div>
                    
                  
                  </div>  

                  
                </div>
                <div style="width:8.3%;display: table-cell;"></div>
                    <div style="width:8.3%;display: table-cell;"></div>
                    <div style="width:8.3%;display: table-cell;"></div>
 
 
          </td>
      
      </tr>

  

    
 
      </tbody>
    </table>



    





    <application-details-modal :application-id="chosenApplication ? chosenApplication.correspondenceID : null"></application-details-modal>
  </div>
</template>

<script>
import ApplicationResource from '../../api/applicationResource';
import SkList from '../skeletons/sk-list';
import ApplicationDetailsModal from './application-details-modal';

const applicationResource = new ApplicationResource();

export default {
  name: 'application-list',
  components: { SkList, ApplicationDetailsModal },
  data() {
    return {
      applications: [],
      loading: false,
      chosenApplication: null,
      progress:[]
    }
  },

  mounted() {
    this.loading = true;
    applicationResource.myApplications().then(response => {

     
      this.applications = response.data.data;
      this.loading = false;
    })
  },

  methods: {
    showApplication(application) {
      this.chosenApplication = application;
      this.$bvModal.show('application-details-modal');
    },
    downloadPDF(correspondenceID){
      applicationResource.downloadPDF(correspondenceID).then(response=>{

        const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', correspondenceID+'.pdf');
          document.body.appendChild(link);
          link.click();



      })
      

    },


    getApplicationProgress(correspondenceID){

      

      applicationResource.getApplicationProgress(correspondenceID).then(response=>{ 
        this.progress=response.data 

         

        let td=document.getElementById('progress_'+correspondenceID)

         if(td.style.display=='none'){

          td.style.display='table-row'
         }else {
          td.style.display='none'
         }

      })


 

      },




  }
};
</script>
<style scoped>
  td {
    min-height: 40px;
    padding-right: 4px;
    padding-left: 4px;
  }


  tbody,thead{
    display: contents;
  }

  tr{
    display: table-row-group;
  }

  .progressBox{

 
color: black;
padding: 10px;
display: table-cell;
width: 50%;
border-top: 1px solid grey;
text-align: center;
 
 
 

}
 
</style>
