<template>
    <div class="container-fluid">
        <div class="row">
            <sidebar></sidebar>
            <!-- MAIN CONTENT start -->
            <main role="main" class="col-12 col-sm-9 ml-sm-auto col-lg-10">
                    <div class="row">
                        <div class="col-12 mb-5">
                            <app-header></app-header>
                            <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>
                        </div>
                    </div>
                        <div class="row">
                          <div class="col-12 col-lg-9">
                            <div class="border-round d-lg-flex align-items-center justify-content-start flex-wrap flex-lg-nowrap p-3 bg-white-88">
                              <div class="d-lg-flex align-items-center mr-4 pr-2 mt-3 mt-lg-0">
                                <img src="/static/images/icons/evaluations/recover.png" alt="sent" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                                <span class="s-b-1">{{ $t('applications.sent') }}</span>
                              </div>
                              <div class="d-lg-flex align-items-center mr-4 pr-2 mt-3 mt-lg-0">
                                <img src="/static/images/icons/evaluations/accept-ic.png" alt="approved" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                                <span class="s-b-1">{{ $t('applications.approved') }}</span>
                              </div>
                              <div class="d-lg-flex align-items-center mr-4 pr-2 mt-3 mt-lg-0">
                                <img src="/static/images/icons/evaluations/accept.png" alt="satisfied" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                                <span class="s-b-1">{{ $t('applications.satisfied') }}</span>
                              </div>
                              <div class="d-lg-flex align-items-center mr-4 pr-2 mt-3 mt-lg-0">
                                <img src="/static/images/icons/evaluations/fail.png" alt="notsatisfied" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                                <span class="s-b-1">{{ $t('applications.notsatisfied') }}</span>
                              </div>
                              <div class="d-lg-flex align-items-center mr-4 pr-2 mt-3 mt-sm-0">
                                <img src="/static/images/icons/evaluations/fail-ic.png" alt="cancelled" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                                <span class="s-b-1">{{ $t('applications.cancelled') }}</span>
                              </div>
                            </div>
                          </div>

                          <div class="col-12 col-lg-3 text-right">
                            <router-link to="/my-applications/new"
                                         class="border-round btn bg-red text-white mt-lg-0 mt-3 py-3 s-b-1">{{ $t('applications.write-application') }}</router-link>
                          </div>

                            <!-- application table -->
                            <div class="col-12 mt-5">
                         
                                <application-list></application-list>
                            </div>
                        </div>
            </main>
        </div>
    </div>
</template>
<script>
import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';
import ApplicationList from '../components/applications/application-list';

export default {
    name:'my-applications',
    components: { sidebar, appHeader, pageHeader, ApplicationList },
    computed: {
        pageData() {
        return {
            title: this.$t('applications.title'),
            breadcrumb: [{
            icon: '',
            title: this.$t('home.title'),
            link: '/',
            isActive: false,
            },
            {
            icon: '',
            title: this.$t('applications.title'),
            link: '/my-applications',
            isActive: true,
            }],
        };
        },
    },
}
</script>
