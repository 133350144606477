<template>
  <b-modal
    @shown="onShown()"
    :hide-header="true"
    :hide-footer="true"
    content-class="modal-blue"
    id="application-details-modal"
    size="lg"
    scrollable
    centered>
    <sk-list v-if="loading" :height="0.03" :items-count="6"></sk-list>
    <div v-if="!loading && application" class="application-container row">
      <div class="col-12 text-right mb-5">
        <div class="application-head" v-html="helpers.getLocalizedField(application, 'head')"></div>
      </div>
      <div class="col-12 text-center">
        <div class="application-content" v-html="helpers.getLocalizedField(application, 'text')"></div>
      </div>

       



      <div class="col-12 mt-5">
        <button type="button" class="btn btn-light-bold" @click="hideModal()">{{ $t('applications.close') }}</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import SkList from '../skeletons/sk-list';
import ApplicationResource from '../../api/applicationResource';

const applicationResource = new ApplicationResource();

export default {
  name: 'application-details-modal',

  props: ['application-id'],

  components: { SkList },

  data() {
    return {
      application: null,
      loading: false,
     
    }
  },

  methods: {
    hideModal() {
      this.$bvModal.hide('application-details-modal');
    },

    load() {
      this.loading = true;
      applicationResource.getApplication(this.applicationId)
      .then(response => {
        this.application = response.data.data;
      })
      .catch(error => {
        this.helpers.notifyErrorMessage(error);
      })
      .finally(() => {
        this.loading = false;
      })
    },

   

    onShown() {
      this.load();
     
    }
  }
};
</script>

<style scoped>
  .application-content {
    min-height: 24vh;
  }
   
</style>
