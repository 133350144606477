<template>
  <!--Page header-->
  <div class="page-header">
    <div class="d-none d-sm-none d-md-block">
      <breadcrumb :items="breadcrumb"></breadcrumb>
    </div>
    <div class="page-leftheader">
      <page-title :title="pageTitle"></page-title>
    </div>
  </div>
  <!--End Page header-->
</template>

<script>
  import PageTitle from './page-title';
  import Breadcrumb from './breadcrumb';

  export default {
    name: 'page-header',
    components: { Breadcrumb, PageTitle },
    props: ['pageTitle', 'breadcrumb'],
  };
</script>

<style scoped>

</style>
