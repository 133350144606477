<template>
  <!--app header-->
  <div class="app-header header top-header">
    <div class="container-fluid">
      <nav-header></nav-header>
    </div>
  </div>
  <!--/app header-->
</template>

<script>
  import NavHeader from './navbar/nav-header';

  export default {
    name: 'app-header',
    components: { NavHeader },
  };
</script>

<style scoped>

</style>
